import React, { useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from '@arco-design/web-react';
import { IconImage } from '@arco-design/web-react/icon';

const MenuItem = Menu.Item;

// 网站菜单
function AppMenu() {

  const location = useLocation(); // 获取当前的路由位置

  // 菜单内容
  const [ menuList, setMenuList ] = useState([
    {
      id: '1',
      title: '图片压缩',
      icon: IconImage,
      key: '/CompressImage'
    },
    {
      id: '2',
      title: 'heic格式转换',
      icon: IconImage,
      key: '/Heic2CommonFormat'
    }
  ])

  const navigate = useNavigate();
  // 点击导航跳转
  function toPage(key) {
    console.log(key)
    navigate(key)
  }

  return (
    <div>
      <Menu
        selectedKeys={location.pathname}
        onClickMenuItem={toPage}
        style={{ width: '100%' }}
      >
        {
          menuList.map((menuItem) => {
            return (
              <MenuItem key={menuItem.key}>
                <IconImage />
                {menuItem.title}
              </MenuItem>
            )
          })
        }
        {/* <MenuItem key='/CompressImage'>
          <IconImage />
          图片压缩
        </MenuItem>
        <MenuItem key='/CompressImage'>
          <IconImage />
          heic格式转换
        </MenuItem> */}
      </Menu>
    </div>
  )
}

export default AppMenu