import React, { Suspense, lazy } from 'react'
import App from './App'
import Welcome from './pages/Welcome'

const CompressImage = lazy(() => import('./pages/CompressImage'))
const Heic2CommonFormat = lazy(() => import('./pages/Heic2CommonFormat'))
const { createBrowserRouter } = require("react-router-dom")

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Welcome></Welcome>
      },
      {
        path: "CompressImage",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CompressImage />
          </Suspense>
        )
      },
      {
        path: "Heic2CommonFormat",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Heic2CommonFormat />
          </Suspense>
        )
      }
    ]
  }
])

export default router